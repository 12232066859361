import React, { Component, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import './AdminTransactionDetailScreen.css';
import withProtection from './hoc/WithProtection';
import withSingleColumnLayout from './hoc/WithSingleColumnLayout';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import NPCard from '../cards/NPCard';
import MarketplaceAPI from '../api/MarketplaceAPI';
import * as d3 from 'd3';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const cookies = new Cookies();

function AdminAnalyticsScreen() {

    const [sessionsPerDay, setSessionsPerDay] = useState([])
    const graphRef = useRef(null);

    const history = useHistory()

    const loadMarketplaceAnalytics = async () => {

        const sessionsPerDayResponse = await new MarketplaceAPI().getSessionsPerDay();

        if (sessionsPerDayResponse) setSessionsPerDay(sessionsPerDayResponse);

    }

    const renderChart = () => {
        // Remove existing chart if any (cleanup)
        d3.select(graphRef.current).selectAll('*').remove();

        const containerWidth = graphRef.current.clientWidth;

        // SVG dimensions and margins
        const margin = { top: 30, right: 12, bottom: 50, left: 12 };
        const width = containerWidth - margin.left - margin.right;
        const height = 400 - margin.top - margin.bottom;

        // Parse date format
        const parseTime = d3.timeParse("%Y-%m-%d");

        // Convert date strings to JavaScript Date objects
        const data = sessionsPerDay.map(d => ({
            ...d,
            _id: parseTime(d._id)
        }));

        // Create SVG container
        const svg = d3.select(graphRef.current)
            .append("svg")
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // Set the scales
        const x = d3.scaleBand()
            .range([0, width])
            .padding(0.1)
            .domain(data.map(d => d._id));

        const y = d3.scaleLinear()
            .range([height, 0])
            .domain([0, d3.max(data, d => d.sessionCount)]);

        // Add X axis (only labels, no line or ticks)
        const xAxis = d3.axisBottom(x).tickFormat(d3.timeFormat("%b %d"));

        const xAxisGroup = svg.append("g")
            .attr("transform", `translate(0,${height})`)
            .call(xAxis);

        // Remove x-axis line and ticks, keeping only the labels
        xAxisGroup.selectAll("path").style("display", "none");  // Hide axis line
        xAxisGroup.selectAll("line").style("display", "none");  // Hide tick lines

        // Rotate the x-axis labels
        xAxisGroup.selectAll("text")
            .attr("transform", "rotate(-45)")
            .style("text-anchor", "end");

        // Add Y axis
        //  svg.append("g")
        //      .call(d3.axisLeft(y));

        // Add the bars
        svg.selectAll(".bar")
            .data(data)
            .enter()
            .append("rect")
            .attr("class", "bar")
            .attr("x", d => x(d._id))
            .attr("y", d => y(d.sessionCount))
            .attr("width", x.bandwidth())
            .attr("height", d => height - y(d.sessionCount))
            .attr("fill", "steelblue")
            .on("click", (event, d) => {
                history.push('/admin/analytics/sessions', {date: d._id})
            })

        // Add the text labels on top of each bar
        svg.selectAll(".label")
            .data(data)
            .enter()
            .append("text")
            .attr("class", "label")
            .attr("x", d => x(d._id) + x.bandwidth() / 2)  // Position the text in the center of each bar
            .attr("y", d => y(d.sessionCount) - 5)  // Position the text slightly above the bar
            .attr("text-anchor", "middle")  // Center the text
            .text(d => d.sessionCount)      // The text to display (session count)
            .attr("fill", "black")          // Set text color
            .style("font-size", "var(--font-small)");    // Set text size

    }

    useEffect(loadMarketplaceAnalytics, [])
    useEffect(() => { if (sessionsPerDay.length > 0) { renderChart(); } }, [sessionsPerDay]);

    return (
        <div className="admin-transaction-detail-screen">
            <NPCard>
                <div style={{ textAlign: 'left', fontSize: 'var(--font-large)' }}>Marketplace: Sessioni giornaliere</div>
                <div style={{ textAlign: 'left', fontSize: 'var(--font-small)', marginBottom: 24 }}>Marketplace: Affare Trattore</div>
                <div className='graph' ref={graphRef}></div>
            </NPCard>
        </div>
    )
}
function Title() {

    return (
        <div className="admin-transactions-title">
        </div>
    )
}


export default withProtection(
    withSingleColumnLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(AdminAnalyticsScreen)),
        "wide"
    )
);