import './UserSessionDetailsScreen.css'
import './Screens.css'
import React, { Component, useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import withProtection from '../hoc/WithProtection';
import withSingleColumnLayout from '../hoc/WithSingleColumnLayout';
import { withRouter } from 'react-router-dom';
import Cookies from 'universal-cookie';
import NPCard from '../../cards/NPCard';
import NPList from '../../comp/list/NPList';
import MarketplaceAPI from '../../api/MarketplaceAPI';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { NPLoadingBar } from '../../comp/NPLoadingBar';
import { NPTag } from '../../comp/NPTag';
import moment from 'moment-timezone';

const cookies = new Cookies();

function UserSessionDetailsScreen() {

    const [activities, setActivities] = useState([])
    const [fieldsFilled, setFieldsFilled] = useState()

    const { sessionId } = useParams()

    /**
     * Loads the user session
     */
    const loadSession = async () => {

        const response = await new MarketplaceAPI().getSessionDetails(sessionId)

        setActivities(response)

        let accumulatedFilledFields = new Set()

        for (let activity of response) {
            let filled = determineFieldBeingFilled(activity.data)
            if (filled) filled.forEach(item => accumulatedFilledFields.add(item))
        }

        setFieldsFilled(accumulatedFilledFields);

    }

    /**
     * Given the data of an activity (field "data"), this method will determine which fields are filled
     */
    const determineFieldBeingFilled = (data) => {

        const fields = []
        const fieldsToSkip = ["userType", "adId", "adImageURL", "marketplaceSellerFlag", "adTitle", "marketplaceSellerId"]

        for (let k of Object.keys(data)) {

            // List of fields to skip
            if (fieldsToSkip.includes(k)) continue;

            // Check if the fields has a value and if it does, add it
            if (data[k] != null && data[k]) fields.push(k)
        }

        return fields
    }

    /**
     * Formats the data for the list view
     */
    const prepData = (item) => {

        let filledFields = determineFieldBeingFilled(item.data)
        if (filledFields) filledFields = filledFields.join(", ")

        return {
            values: [
                { value: moment(item.ts, 'YYYY.MM.DD HH:mm:ss').format('HH:mm:ss') },
                { value: item.activity },
                { value: filledFields }
            ]
        }
    }

    useEffect(loadSession, [])

    return (
        <div className="admin-user-session-detail-screen screen">
            <div className='precard-title'>User Activity on session {sessionId}</div>
            <NPCard highlight="left" highlightColor="primary">
                {!fieldsFilled && <NPLoadingBar />}
                {fieldsFilled &&
                    <div>
                        <div className='tags-title'>L'utente ha compilato i campi seguenti:</div>
                        <div className='tags-container'>{
                            [...fieldsFilled].map((item) => {
                                return (
                                    <NPTag text={item} />
                                )
                            })
                        }</div>
                    </div>
                }
            </NPCard>
            <div className='precard-title' style={{ textAlign: 'left' }}>Detailed log of User Activity</div>
            <div className='no-padding-cards'>
                <NPCard>
                    <NPList
                        data={activities}
                        headers={["Timestamp", "Activity", "Fields being filled"]}
                        types={["string", "string", "string"]}
                        elementsSizing={["20%", "30%", null]}
                        dataConverter={prepData}
                    />
                </NPCard>
            </div>
        </div>
    )
}
function Title() {

    return (
        <div className="admin-transactions-title">
        </div>
    )
}


export default withProtection(
    withSingleColumnLayout(
        withTranslation()(Title),
        withRouter(withTranslation()(UserSessionDetailsScreen)),
        "wide"
    )
);